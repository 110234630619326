import Vue from 'vue'
import App from './App.vue'
import router from './router';
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import "@mdi/font/css/materialdesignicons.min.css"
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
require("@/assets/css/my.css");
import VueMoment from 'vue-moment';
import moment from "moment";
// import VueNumerals from 'vue-numerals';


Vue.config.productionTip = false



Vue.use(
    VueToast, {
        position: 'top',
        duration : 3000
    }
);
Vue.use(VueMoment, {
    moment,
})

// Vue.component(moment);

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')