import Vue from "vue";
import Router from "vue-router";
import VueHead from "vue-head";
import NProgress from 'nprogress';

import Config from '@/conf/Config.js';
import axios from 'axios';

Vue.use(VueHead);
Vue.use(Router);

/* eslint-disable */
/** sweet alert */
import swal from "@/assets/swal/sweet-alert.min.js";
require("@/assets/swal/sweet-alert.css");
require("@/assets/swal/theme.css");

const router = new Router({
    // mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: "/",
            name: "Login",
            component: () => import("./views/Login.vue"),

        },
        {
            path: "/home",
            name: "Dashboard",
            component: () => import('./views/Induk.vue'),
            meta: {
                auth: true,
                // roles: ['1','2','3','4']
            },
            children: [
                {
                    path: "/dashboard-admin",
                    name: "dashboard-admin",
                    component: () => import("./views/Dashboard.vue"),
                    meta: {
                        auth: true,
                        roles: ['1','6']
                    }
                },
                {
                    path: "/dashboard-malang",
                    name: "dashboard-malang",
                    component: () => import("./views/dashboard/CabMlg.vue"),
                    meta: {
                        auth: true,
                        roles: ['1','6']
                    }
                },
                {
                    path: "/dashboard-semarang",
                    name: "dashboard-semarang",
                    component: () => import("./views/dashboard/CabSmg.vue"),
                    meta: {
                        auth: true,
                        roles: ['1','6']
                    }
                },
                {
                    path: "/master/approved",
                    name: "approved",
                    component: () => import("./views/approved/Approved.vue"),
                    meta: {
                        auth: true,
                        roles: ['1','6']
                    }
                },
                {
                    path: "/dashboard/customer",
                    name: "customer",
                    component: () => import("./views/dashboard/Customer.vue"),
                    meta: {
                        auth: true,
                        roles: ['1','6']
                    }
                },
                {
                    path: "/dashboard/suplier",
                    name: "suplier",
                    component: () => import("./views/dashboard/Supplier.vue"),
                    meta: {
                        auth: true,
                        roles: ['1','6']
                    }
                },
                {
                    path: "/master/lokasi",
                    name: "cek lokasi",
                    component: () => import("./views/master/Simcard.vue"),
                    meta: {
                        auth: true,
                        roles: ['1']
                    }
                },
                {
                    path: "/dashboard-kendaraan",
                    name: "dashboard-kendaraan",
                    component: () => import("./views/kendaraan/Dashboard.vue"),
                    meta: {
                        auth: true,
                        roles: ['3']
                        // title : 'Main',
                    }
                },
                {
                    path: "/dashboard-purchase",
                    name: "dashboard-purchasing",
                    component: () => import("./views/purchase/Dashboard.vue"),
                    meta: {
                        auth: true,
                        roles: ['2']
                        // title : 'Main',
                    }
                },
                {
                    path: "/dashboard-gudang",
                    name: "dashboard-gudang",
                    component: () => import("./views/gudang/Dashboard.vue"),
                    meta: {
                        auth: true,
                        roles: ['4']
                        // title : 'Main',
                    }
                },
                {
                    path: "/profile",
                    name: "Profile",
                    component: () => import("./views/Profile.vue"),
                    meta: {
                        auth: true,
                        roles: ['1','2','3','4','5','6']
                        // title : 'Profile',
                    }
                },
                // gudang user [4]
                {
                    path: "/gudang",
                    name: "Gudang",
                    component: () => import("./views/master/Gudang.vue"),
                    meta: {
                        auth: true,
                        roles: ['1','4']
                        // title : 'Gudang',
                    }
                },
                {
                    path: "/i-gudang",
                    name: "Index Gudang",
                    component: () => import("./views/gudang/Gudang.vue"),
                    meta: {
                        auth:true,
                        roles: ['1','4']
                    }
                },
                {
                    path: "/m-gudang",
                    name: "Mutasi Gudang",
                    component: () => import("./views/gudang/Mutasi.vue"),
                    meta: {
                        auth:true,
                        roles: ['1','4']
                    }
                },
                {
                    path: "/do",
                    name: "do",
                    component: () => import("./views/gudang/Do.vue"),
                    meta: {
                        auth:true,
                        roles: ['1','4','5']
                    }
                },
                // purchasing & inventaris user [2]
                {
                    path: "/d-supplier",
                    name: "D-Supplier",
                    component: () => import("./views/dashboard/Supplier.vue"),
                    meta: {
                        auth: true,
                        roles: ['1','2']
                        // title : 'Main',
                    }
                },
                {
                    path: "/item",
                    name: "Item",
                    component: () => import("./views/master/Item.vue"),
                    meta: {
                        auth: true,
                        roles: ['1','4']
                        // title : 'Item',
                    }
                },
                {
                    path: "/itemstock",
                    name: "ItemStock",
                    component: () => import("./views/report/ItemStock.vue"),
                    meta: {
                        auth: true,
                        roles: ['1','2']
                        // title : 'Kunjungan',
                    }
                },
                {
                    path: "/inventori",
                    name: "Inventaris",
                    component: () => import("./views/inventaris/Index.vue"),
                    meta: {
                        auth:true,
                        roles: ['1','2']
                    }
                },
                {
                    path: "/cek-stok",
                    name: "stok",
                    component: () => import("./views/report/ItemStock.vue"),
                    meta: {
                        auth:true,
                        roles: ['1','2','5']
                    }
                },
                {
                    path: "/lokasi-inventaris",
                    name: "Lokasi Inventaris",
                    component: () => import("./views/inventaris/Lokasi.vue"),
                    meta: {
                        auth:true,
                        roles: ['1','2']
                    }
                },
                {
                    path: "/supp-inventaris",
                    name: "Supplier Inventaris",
                    component: () => import("./views/inventaris/Supp.vue"),
                    meta: {
                        auth:true,
                        roles: ['1','2']
                    }
                },
                {
                    path: "/po-inventaris",
                    name: "Order Inventaris",
                    component: () => import("./views/inventaris/Po.vue"),
                    meta: {
                        auth:true,
                        roles: ['1','2']
                    }
                },
                {
                    path: "/purchase/datapo",
                    name: "PO Detail",
                    component: () => import("./views/purchase/Pengajuan.vue"),
                    meta: {
                        auth:true,
                        roles: ['1','2']
                    }
                },
                // kendaraan user [3]
                {
                    path: "/perbaikan",
                    name: "Perbaikan",
                    component: () => import("./views/kendaraan/Perbaikan.vue"),
                    meta: {
                        auth:true,
                        roles: ['1','3']
                    }
                },
                {
                    path: "/kendaraan",
                    name: "Data Kendaraan",
                    component: () => import("./views/kendaraan/DataKendaraan.vue"),
                    meta: {
                        auth:true,
                        roles: ['1','3']
                    }
                },
                {
                    path: "/stafel",
                    name: "Stafel",
                    component: () => import("./views/kendaraan/Stafel.vue"),
                    meta: {
                        auth:true,
                        roles: ['1','3','5']
                    }
                },
                {
                    path: "/inventaris/:inv_id",
                    name: "Inventaris Detail",
                    component: () => import("./views/inventaris/ItemDetail.vue"),
                    props: true,
                    meta: {
                        auth:true,
                        roles: ['1','2']
                    }
                },
                // marketing user [5]
                {
                    path: "/dashboard-marketing",
                    name: "Dashboard Marketing",
                    component: () => import("./views/marketing/Index.vue"),
                    props: true,
                    meta: {
                        auth:true,
                        roles :['5']
                    }
                },
                {
                    path: "/sales/penjualan",
                    name: "Penjualan",
                    component: () => import("./views/sales/Penjualan.vue"),
                    meta: {
                        auth: true,
                        roles: ['1','6']
                        // title : 'Target',
                    }
                },
                {
                    path: "/sales/target",
                    name: "Target",
                    component: () => import("./views/sales/Target.vue"),
                    meta: {
                        auth: true,
                        roles: ['1','6']
                        // title : 'Target',
                    }
                },
                {
                    path: "/sales/kunjungan",
                    name: "Kunjungan",
                    component: () => import("./views/sales/Kunjungan.vue"),
                    meta: {
                        auth: true,
                        roles: ['1','6']
                        // title : 'Kunjungan',
                    }
                },
                {
                    path: "/customer", //data customer
                    name: "Customer",
                    component: () => import("./views/master/Customer.vue"),
                    meta: {
                        auth: true,
                        roles: ['1','5','6']
                        // title : 'Customer',
                    }
                },
                {
                    path: "/location", //cek lokasi customer
                    name: "Location",
                    component: () => import("./views/master/Location.vue"),
                    meta: {
                        auth: true,
                        roles: ['1']
                        // title : 'Location',
                    }
                },
                {
                    path: "/checkin", //cekin kunjungan
                    name: "Checkin",
                    component: () => import("./views/Checkin.vue"),
                    meta: {
                        auth: true,
                        roles: ['1']
                        // title : 'Checkin',
                    }
                },
                {
                    path: "/pengajuan",
                    name: "Pengajuan Customer",
                    component: () => import("./views/marketing/Pengajuan.vue"),
                    meta: {
                        auth: true,
                        roles: ['1','5']
                        // title : 'Location',
                    }
                },
                {
                    path: "/marketing/approved",
                    name: "Approved Pengajuan",
                    component: () => import("./views/marketing/Approved.vue"),
                    meta: {
                        auth: true,
                        roles: ['1','5']
                        // title : 'Location',
                    }
                },
                {
                    path: "/marketing/outstanding",
                    name: "Data Sales Order",
                    component: () => import("./views/marketing/Outstanding.vue"),
                    meta: {
                        auth: true,
                        roles: ['1','5']
                        // title : 'Location',
                    }
                },

                // galat
                {
                    path: "/permission-denied",
                    name: "404",
                    component: () => import("./views/404.vue"),
                    meta: {
                        auth: true,
                        roles: ['1','2','3','4','5','6']
                        // title : 'Main',
                    }
                },

            ]
        }
    ]
});

const hasPermission = (userDepartment, requiredRoles) => {
    return requiredRoles.includes(userDepartment);
  };

const getUserRole = () => {
    return localStorage.getItem('dept');
  };
const getUserLevel = () => {
    return localStorage.getItem('level');
  };

  const redirectToDashboard = (userDepartment, userLevel, to, next) => {
    let dashboardRoute = '/home';

    if (userDepartment === '1') {
      dashboardRoute = '/dashboard-admin';
    } else if (userDepartment === '2') {
      dashboardRoute = '/dashboard-purchase';
    } else if (userDepartment === '3') {
      dashboardRoute = '/dashboard-kendaraan';
    } else if (userDepartment === '4') {
      dashboardRoute = '/dashboard-gudang';
    } else if (userDepartment === '5') {
      dashboardRoute = '/dashboard-marketing';
    } else if (userDepartment === '6') {
      dashboardRoute = '/dashboard-admin';
    } else if (userDepartment === '6' && userLevel === '3') {
      dashboardRoute = '/dashboard-malang';
    }

    console.log('Redirecting to dashboard:', dashboardRoute);

    if (to.path !== dashboardRoute) {
      next({ path: dashboardRoute });
    } else {
      next();
    }
  };

  router.beforeEach(async (to, from, next) => {
    try {
      if (to.matched.some(record => record.meta.auth)) {
        axios.defaults.baseURL = Config.apiUrl;
        axios.defaults.headers.common['Authorization'] = localStorage.tsalestoken;

        const response = await axios.post('admin/ApiAdmin.php', {
          pfunction: 'checklogin'
        });

        if (response.data.success) {
          const userDepartment = getUserRole();
          const userLevel = getUserLevel();

          if (!to.meta.auth) {
            next();
          } else {
            const requiredRoles = to.meta.roles || [];


            if (hasPermission(userDepartment, requiredRoles, userLevel)) {
              if (to.name === 'Login') {
                redirectToDashboard(userDepartment , userLevel, to, next);
            } else {

                next();
              }
            } else {
              console.log('User does not have the required roles. Redirecting to permission-denied.');
              next({ path: '/permission-denied' });
            }
          }
        } else {
          next("/");
        }
      } else {
        next();
      }
    } catch (error) {
      console.error('Error in router navigation guard:', error);
      next("/login");
    }
  });

  router.beforeResolve((to, from, next) => {
    if (to.name) {
      NProgress.start();
    }
    next();
  });

  router.afterEach((to, from) => {
    NProgress.done();
  });

  export default router;